<template>
  <NuxtLink :to="'/news/' + article.d_news_id.slug"
            class="news-preview">
    <figure class="news-preview__image">
      <img v-if="article.d_news_id.image"
           :src="img(article.d_news_id.image, { width: 300, height: 300, fit: 'cover' })"
           :alt="$t('accessibility.news_image')">
    </figure>
    <div class="news-preview__content">
      <div>
        <span class="news-preview__date">{{ $d(article.d_news_id.date_created) }}</span>
        <h3 class="news-preview__title"
            :lang="usePlatformSettings().locale">{{ article.title }}</h3>
      </div>
      <p class="news-preview__text">
        {{ truncate(article.abstract, 60) }}</p>
    </div>
  </NuxtLink>
</template>
<script setup lang="ts">
const { getThumbnail: img } = useDirectusFiles();
const props = defineProps<{
  article: NewsArticle;
}>();

function truncate(str: string, n: number) {
  return (str.length > n) ? str.slice(0, n - 1) + '...' : str;
};
</script>

<style lang="scss">
.news-preview {
  grid-column: span 6;
  background-color: #fff;
  display: flex;
  border-radius: 1rem;
  overflow: hidden;
  padding: 2px;
  align-items: stretch;

  &__image {
    position: relative;
    background-color: rgb(var(--primary));
    flex-shrink: 0;
    height: 100%;
    border-radius: calc(1rem - 2px);
    overflow: hidden;

    img {
      width: 9rem;
      min-height: 9rem;
      object-fit: cover;
      height: 100%;
    }
  }

  &__content {
    padding: 1rem;
  }

  &__date {
    display: block;
    font-size: .8rem;
  }

  &__title {
    margin-top: .5rem;
    margin-bottom: .25rem;
    transition: color .1s;
  }

  &__date,
  &__title,
  &__text {
    line-height: 1.3;
    color: rgb(var(--secondary));
  }

  &:hover {
    .news-preview__title {
      color: rgb(var(--primary));
    }
  }

  @include bp-md {
    grid-column: span 12;

    &__image {
      img {
        height: 8rem;
      }
    }

    &__title {
      margin: 0;
    }
  }

}
</style>